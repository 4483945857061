import React, { useEffect, useState } from "react";
import {
  Nav,
  NavbarBrand,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button,
} from "reactstrap";

import logo from "../../images/TEXEL.svg";
import ScrollspyNav from "./scrollSpy";

const NavbarPage = ({ navItems }) => {
  const [state, setState] = useState({
    navClass: "",
  });

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, []);

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;

    if (scrollup > 50) {
      setState({
        navClass: "is-sticky",
      });
    } else {
      setState({
        navClass: "",
      });
    }
  };

  // Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
  let targetId = navItems.map((item) => {
    return item.idnm;
  });

  const whatsappLink = "https://wa.me/573175881177?text=Hola,%20estoy%20interesado%20en%20contactarlos!";

  return (
    <React.Fragment>
      <div id="navbar" className={state.navClass}>
        <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
          <Container>
            <NavbarBrand className="logo text-uppercase" href="/">
              <img src={logo} alt="logo" height="30" />
            </NavbarBrand>

            {/* Button visible only on mobile */}
            <div className="ms-auto d-lg-none"> {/* Show only on mobile */}
              <a
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn navbar-btn btn-rounded waves-effect waves-light contactButton"
              >
                Contactanos
              </a>
            </div>

            <div className="d-flex align-items-center w-100">
              <Collapse id="navbarCollapse" isOpen={true} navbar>
                <ScrollspyNav
                  scrollTargetIds={targetId}
                  scrollDuration="800"
                  headerBackground="true"
                  activeNavClass="active"
                  className="navbar-collapse justify-content-center"
                >
                  {/* Links visible only on desktop */}
                  <Nav navbar className="navbar-center d-none d-lg-flex"> {/* Hide on mobile */}
                    {navItems.map((item, key) => (
                      <NavItem
                        key={key}
                        className={item.navheading === "Home" ? "active" : ""}
                      >
                        <NavLink href={"#" + item.idnm}>
                          {item.navheading}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </ScrollspyNav>
              </Collapse>

              {/* Button always visible */}
              <div className="ms-auto d-none d-lg-block"> {/* Show only on desktop */}
                <a
                  href={whatsappLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn navbar-btn btn-rounded waves-effect waves-light contactButton"
                >
                  Contactanos
                </a>
              </div>
            </div>
          </Container>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default NavbarPage;
