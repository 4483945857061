import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import texelLogo from "../../images/Texel2.png";

const Resume = ({ sectionClass }) => {
  return (
    <React.Fragment>
      <section className={"section " + sectionClass} id="services">
        <Container>
          <Row className="align-items-center">
            {/* Logo Section */}
            <Col lg="6" className="text-center">
              <img
                src={texelLogo}
                alt="Texel Bit"
                className="img-fluid texel-logo"
              />
            </Col>
            {/* Text Section */}
            <Col lg="6">
              <div className="text-wrapper">
                <h2 className="section-title">Bienvenido a Texel Bit</h2>
                <p className="section-text">
                  En <strong>Texel Bit</strong>, transformamos ideas en experiencias vibrantes. Combinamos 
                  <em> creatividad</em>, <em>tecnología</em> e <em>innovación</em> para desarrollar videojuegos, 
                  aplicaciones web y móviles que dejan huella. Nos apasiona crear soluciones a medida 
                  que no solo impresionan, sino que también inspiran. 🚀
                </p>
 
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Resume;
