import React, { useState } from "react";
import { Container } from "reactstrap";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import texelLogo from "../../images/TexelWhite.png"

import bottomImage from "../../images/bg.png"; // Fixed the import

const Section = () => {
  const [state, setState] = useState({
    isOpen: false,
  });

  const callModal = () => {
    setState({ isOpen: true });
  };

  const closeModal = () => {
    setState({ isOpen: false });
  };

  // Particles
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <React.Fragment>
      <section className="section bg-home vh-100" id="home">
        <div className="bg-overlay">
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              style: {
                position: "absolute",
              },
              fpsLimit: 120,
              interactivity: {
                events: {
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 100,
                    duration: 9,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffffff",
                  opacity: 0.3,
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.3,
                  width: 2,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 1,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 900,
                  },
                  value: 100,
                },
                opacity: {
                  value: 0.2,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
          />
        </div>

        <div>
          <Container className="slidero">

          <img
    src={texelLogo}
    alt="Texel Logo"
    className="header-image"
  />
            
          <h1 className="text-white text-center home-title">

    <span className="highlight">creamos</span>  soluciones que marcan la diferencia
  </h1>
          </Container>
          {/* Image at the bottom */}
          <div className="bottom-image">
            <img
              src={bottomImage} // Corrected usage
              alt="Bottom Decoration"
              className="bottom-image-style"
            />
          </div>
        </div>

      </section>
    </React.Fragment>
  );
};

export default Section;
