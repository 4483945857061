import React, { useState } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Service from "../../components/Service/service";
import AboutUs from "../../components/AboutUs/AboutUs";
import WebsiteDesc from "../../components/WebsiteDesc/WebsiteDesc";
import Pricing from "../../components/Pricing/pricing";
import Team from "../../components/Team/Team";
import Process from "../../components/Process/Process";
import Testimonials from "../../components/Testimonials/Testimonials";
import GetStart from "../../components/GetStart/GetStart";
import Blog from "../../components/Blog/Blog";
import Contact from "../../components/Contact/Contact";
import Social from "../../components/Social/Social";
import Footer from "../../components/Footer/footer";
import Resume from "../../components/TexelResume/resume";

const Index9 = () => {
  const [state] = useState({
    navItems: [

    ],
    navClass: "",
  });

  return (
    <React.Fragment>
      {/* Importing Navbar */}
      <NavbarPage navItems={state.navItems} navClass={state.navClass} />

      {/* section */}
      <Section />

      {/* services */}
      <Resume />

     {/* pricing */}
     <Pricing />



   
      {/* footer */}
      <Footer />
    </React.Fragment>
  );
};

export default Index9;
