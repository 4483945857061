import React from "react";
import { Container, Row, Col } from "reactstrap";
import FooterLinks from "./footer-links";
import texelShort from "../../images/texelshort.png"; // Fixed the import

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container className="d-flex flex-column align-items-center justify-content-center vh-50">
          <Row className="text-center">
            <Col>
            <h2 className="footer-title">
  <span className="regular-text">¡Vive lo</span> <strong className="bold-text">Inexplorado</strong> <span className="regular-text">ya!</span>
</h2>
            </Col>
          </Row>
          <Row className="mt-4 align-items-center">
            <Col lg={6} md={6} sm={12} className="text-lg-end text-center">
              <img
                src={texelShort}
                alt="Texel Logo"
                className="footer-logo"
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="text-lg-start text-center">
              <p className="footer-contact">
                <br></br>
                <strong>+57 317 588 1177</strong>

                Gerencia@texelbit.com

                <span className="no-wrap">Horarios de atención: 9am - 5pm  (-5 GMT)</span>
              </p>
            </Col>
          </Row>
        </Container>
        <FooterLinks />
      </footer>
    </React.Fragment>
  );
};

export default Footer;
