import React from "react";


import Index9 from "./pages/Index9/Index9";


const routes = [

  { path: "/", component: <Index9 /> },
];

export default routes;
