import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

const PricingBox = ({ pricings }) => {
  return (
    <React.Fragment>
      {pricings.map((pricing, key) => (
        <Col lg={4} key={key}>
          <div
            className={
              pricing.isActive
                ? "pricing-box bg-white hover-effect price-active"
                : "pricing-box hover-effect"
            }
          >
            <h4 className="">{pricing.title}</h4>
            <img src={pricing.icon} alt={pricing.title} className="pricing-icon" />
            <h6 className="descriptionTypeText">
             {pricing.description}
            </h6>
            {/* <div style={{display:"flex", gap:"1rem"}}>
              <Link
                to="#"
                className="btnServiceType waves-effect waves-light mt-4"
              >
                Ver proyectos
              </Link>
              <Link
                to="#"
                className="btnServiceType waves-effect waves-light mt-4"
              >
                Contactar
              </Link>
            </div> */}
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default PricingBox;
