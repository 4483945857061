import React, {  useState } from "react";
import { Container, Row } from "reactstrap";

import gameIcon from "../../images/icons/gameIcon.svg"
import web3DIcon from "../../images/icons/web3D.svg"
import appDevelopmentIcon from "../../images/icons/appIcon.svg"

//Import Pricing
import PricingBox from "./pricing-box";

const Pricing = () => {
  const [state] = useState({
    pricings: [
      {
        title: "Desarrollo de Videojuegos",
        price: 9.9,
        description: "Innovación y creatividad en cada nivel, ofreciendo juegos envolventes con narrativas profundas y mecánicas innovadoras.",
        icon: gameIcon,
        isActive: true,
        features: [
          { title: "Bandwidth", value: "1GB" },
          { title: "Onlinespace", value: "50MB" },
          { title: "Support", value: "No" },
          { title: "Domain", value: "1", isLeft: true },
          // { title: "Hidden Fees", value: "No" },
        ],
      },
      {
        title: "3D Web Interactivo",
        price: 9.9,
        description: "Creación de experiencias web tridimensionales que combinan interactividad avanzada con visualizaciones impactantes.",
        icon: web3DIcon,
        isActive: true,
        features: [
          { title: "Bandwidth", value: "1GB" },
          { title: "Onlinespace", value: "50MB" },
          { title: "Support", value: "No" },
          { title: "Domain", value: "1", isLeft: true },
          // { title: "Hidden Fees", value: "No" },
        ],
      },
      {
        title: "Apps Móviles y Web",
        price: 9.9,
        description: "Diseño y desarrollo de aplicaciones móviles y web a medida, optimizadas para una experiencia de usuario fluida y eficiente.",
        icon: appDevelopmentIcon,
        isActive: true,
        features: [
          { title: "Bandwidth", value: "1GB" },
          { title: "Onlinespace", value: "50MB" },
          { title: "Support", value: "No" },
          { title: "Domain", value: "1", isLeft: true },
          // { title: "Hidden Fees", value: "No" },
        ],
      },
    ],
  });

  return (
    <React.Fragment>
      <section className="" id="pricing">
        <Container>
          {/* section title */}

          <Row className="mt-5 mb-5">
            {/* pricing box */}
            <PricingBox pricings={state.pricings} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Pricing;
